import React from 'react'
import Layout from "../components/layout";
import { Helmet } from 'react-helmet';

function Contact() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Contact - Bart Sallé, freelance webdeveloper";
  const description = "Ik hoor of lees graag jouw wens en word nog steeds enthousiast van elk nieuw project!";
  const url = "https://www.bartsalle.nl/contact/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="contact" />
      </Helmet>
        <div className="container">
          <h1>Contact</h1>
          <p><strong>Voor hulp of advies.</strong></p>
          <p>Bellen of e-mailen gaat natuurlijk het snelst. Uiteraard kan je ook altijd langskomen om, geheel vrijblijvend, je wensen te bespreken. Laat het me van te voren even weten, dan zorg ik dat de koffie (of thee) klaar staat!</p>
          <p><strong>Bart Sallé, freelance webdeveloper</strong></p>
          <p>Paulusland 9<br />1679GV Midwoud</p>
          <p>E-mail: <a href="mailto:info@bartsalle.nl" target="_blank">info@bartsalle.nl</a><br />Tel. : <a href="tel:+31643126237" target="_blank">06-43126237</a><br />KvK: 34252794</p>
        </div>
    </Layout>
  )
}

export default Contact